import { Env, getEnv } from 'helpers/env';

type MFE =
  | 'auth'
  | 'customerProfile'
  | 'dashboard'
  | 'leads'
  | 'leagueLive'
  | 'notifications'
  | 'profile'
  | 'splat'
  | 'support'
  | 'salesMetrics';
type LeadsPage = 'create' | 'view' | 'channelSelection';
type NotificationsPage = 'list' | 'button';
type SplatPage = 'customers' | 'appointments' | 'doors' | 'scheduleAppointment';
type Page =
  | 'login'
  | 'customerProfile'
  | 'dashboard'
  | 'leagueLive'
  | 'profile'
  | 'support'
  | 'salesMetrics'
  | LeadsPage
  | NotificationsPage
  | SplatPage;
type PageConfig = { path: string; title: string; url: string };
type MFEConfig = {
  origin: Record<Env, string>;
  pages: Partial<Record<Page, PageConfig>>;
};
const CONFIG: Record<MFE, MFEConfig> = {
  auth: {
    origin: {
      production: 'https://auth.sunrunone.com',
      staging: 'https://auth.staging.sunrunone.com',
    },
    pages: {
      login: {
        path: '/',
        title: 'Login',
        url: '',
      },
    },
  },
  customerProfile: {
    origin: {
      production: 'https://customer-profile.sunrunone.com',
      staging: 'https://customer-profile.staging.sunrunone.com',
    },
    pages: {
      customerProfile: {
        path: '/customers/customer-profile',
        title: 'Customer Profile',
        url: '',
      },
    },
  },
  dashboard: {
    origin: {
      production: 'https://dashboard.sunrunone.com',
      staging: 'https://dashboard.staging.sunrunone.com',
    },
    pages: {
      dashboard: {
        path: '/dashboard',
        title: 'Dashboard',
        url: '',
      },
    },
  },
  leads: {
    origin: {
      production: 'https://leads.sunrunone.com',
      staging: 'https://leads.staging.sunrunone.com',
    },
    pages: {
      channelSelection: {
        path: '/newLeads/channel-selection',
        title: 'Channel Selection',
        url: '/channel-selection',
      },
      create: {
        path: '/customers/create',
        title: 'Create Customer',
        url: '/create',
      },
      view: {
        path: '/customers/prospects',
        title: 'View Customer',
        url: '/prospects',
      },
    },
  },
  leagueLive: {
    origin: {
      production: 'https://league-live.sunrun.com',
      staging: 'https://majstg-league-live.sunrun.com',
    },
    pages: {
      leagueLive: {
        path: '/performance',
        title: 'League Live',
        url: '',
      },
    },
  },
  notifications: {
    origin: {
      production: 'https://notifications.sunrunone.com',
      staging: 'https://notifications.staging.sunrunone.com',
    },
    pages: {
      list: {
        path: '/list',
        title: 'List',
        url: '/list',
      },
      button: {
        path: '/button',
        title: 'Button',
        url: '/button',
      },
    },
  },
  profile: {
    origin: {
      production: 'https://profile.sunrunone.com',
      staging: 'https://profile.staging.sunrunone.com',
    },
    pages: {
      profile: {
        path: '/profile',
        title: 'Profile',
        url: '',
      },
    },
  },
  splat: {
    origin: {
      staging: 'https://majstg-go.sunrun.com/one',
      production: 'https://go.sunrun.com/one',
    },
    pages: {
      appointments: {
        path: '/appointments',
        title: 'Appointments',
        url: '/appointments',
      },
      customers: {
        path: '/customers',
        title: 'Customers',
        url: '/customers',
      },
      doors: {
        path: '/doors',
        title: 'Doors',
        url: '/doors',
      },
      scheduleAppointment: {
        path: '/schedule-appointment',
        title: 'Schedule Appointment',
        url: '/schedule-appointment',
      },
    },
  },
  support: {
    origin: {
      production: 'https://support.sunrunone.com',
      staging: 'https://support.staging.sunrunone.com',
    },
    pages: {
      support: {
        path: '/support',
        title: 'Support',
        url: '',
      },
    },
  },
  salesMetrics: {
    origin: {
      production:
        'https://10ay.online.tableau.com/t/sunrunbi/views/EmbeddedLandingPageTest/Dashboard1',
      staging:
        'https://10ay.online.tableau.com/t/sunrunbi/views/EmbeddedLandingPageTest/Dashboard1', //Change if we do get a staging url
    },
    pages: {
      salesMetrics: {
        path: '/metrics',
        title: 'Sales Metrics',
        url: '',
      },
    },
  },
};

function getConfig(mfe: MFE, page: Page): PageConfig {
  const env = getEnv();
  const baseUrl = CONFIG[mfe].origin[env];
  const pageConfig = CONFIG[mfe].pages[page];
  if (!pageConfig) {
    throw new Error('Page ' + page + ' is not configured in MFE ' + mfe);
  }
  const config = { ...pageConfig };
  config.url = baseUrl + config.url;
  return config;
}

export type { MFE, LeadsPage, NotificationsPage, SplatPage };
export { CONFIG, getConfig };
