import { ComponentProps, useRef } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useIframeMessages, useSynchronizeUrl } from './common';
import { getConfig } from './config';
import type { IFrame } from 'components/atoms/IFrame';
import { Message } from 'messages';

const useCustomerProfileAdapter = (
  messageHandler: (message: Message | undefined) => Message | undefined = (
    message,
  ) => message,
): ComponentProps<typeof IFrame> => {
  let [searchParams] = useSearchParams();
  const ref = useRef<HTMLIFrameElement | null>(null);
  const config = getConfig('customerProfile', 'customerProfile');
  const basePath = config.url;
  const match = useMatch(`/${config.path}/*`);
  const src = `${basePath}/${match?.params['*'] ?? ''}?${searchParams}`;
  const { staticSrc } = useSynchronizeUrl(ref, src, basePath);
  useIframeMessages(ref, 'CustomerProfile', config.path, messageHandler);
  return {
    ref,
    basePath: basePath,
    src: staticSrc,
    title: config.title,
  };
};

export { useCustomerProfileAdapter };
