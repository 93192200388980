import { useRef } from 'react';
import { LoadingIndicator } from '@sunrun/experience-ui-components';
import { useSearchParams } from 'react-router-dom';
import { TableauNoAccess } from '../molecules/TableauNoAccess';
import { IFrame } from 'components/atoms/IFrame';
import { useTableauLogin } from 'providers/Tableau';
import { useIframeMessages } from 'adapters/common';
import { QueryAuthToken } from 'messages';
import { getConfig } from 'adapters/config';
import { FullscreenBlock } from 'components/atoms/FullscreenBlock';

const eventTransformer = (event: MessageEvent<any>): MessageEvent<any> => {
  const data = event?.data;
  if (data.type === 'QUERY:AUTH_TOKEN') {
    return {
      ...event,
      data: {
        source: 'Sales Metrics',
        type: 'QUERY:AUTH_TOKEN',
      } as QueryAuthToken,
    };
  }
  return event;
};

const SalesMetrics = () => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const config = getConfig('salesMetrics', 'salesMetrics');
  useIframeMessages(ref, 'Sales Metrics', config.path, (message) => message, {
    eventTransformer,
  });
  let [searchParams] = useSearchParams();
  const loginResult = useTableauLogin(searchParams.get('testUserEmail'));

  if (loginResult === 'Loading...') {
    return (
      <FullscreenBlock>
        <LoadingIndicator color="black" message="Logging into Tableau..." />
      </FullscreenBlock>
    );
  }

  if (
    loginResult === 'User not licensed' ||
    loginResult === 'Unable to Verify Access'
  ) {
    return (
      <FullscreenBlock>
        <TableauNoAccess />
      </FullscreenBlock>
    );
  }

  const src = `${config.url}?token=${loginResult}&:embed=yes`;
  return (
    <IFrame basePath={src} ref={ref} title={config.title} src={src}></IFrame>
  );
};

export { SalesMetrics };
