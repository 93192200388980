import { useDashboardAdapter } from 'adapters/dashboard';
import { IFrame } from 'components/atoms/IFrame';

const DashboardIFrame = () => {
  const { basePath, ref, src, title } = useDashboardAdapter();
  return (
    <IFrame basePath={basePath} ref={ref} title={title} src={src}></IFrame>
  );
};

export { DashboardIFrame };
