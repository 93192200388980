import { ComponentProps, useRef } from 'react';
import { useIframeMessages } from './common';
import { getConfig } from './config';
import type { IFrame } from 'components/atoms/IFrame';
import { QueryAuthToken } from 'messages';

const eventTransformer = (event: MessageEvent<any>): MessageEvent<any> => {
  console.log(event?.data);
  const data = event?.data;
  if (data === 'iframe ready') {
    return {
      ...event,
      data: {
        source: 'League Live',
        type: 'QUERY:AUTH_TOKEN',
      } as QueryAuthToken,
    };
  }
  return event;
};

const useLeagueLiveAdapter = (): ComponentProps<typeof IFrame> => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const config = getConfig('leagueLive', 'leagueLive');
  const src = `${config.url}?one`;
  useIframeMessages(ref, 'League Live', config.path, (message) => message, {
    eventTransformer,
  });
  return {
    ref,
    basePath: src,
    src,
    title: config.title,
  };
};

export { useLeagueLiveAdapter };
