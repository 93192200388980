import { ComponentProps, useRef } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useIframeMessages, useSynchronizeUrl } from './common';
import { SplatPage, getConfig } from './config';
import type { IFrame } from 'components/atoms/IFrame';
import { Message } from 'messages';

const useSplatAdapter = (
  page: SplatPage,
  localMessageHandler?: (message: Message | undefined) => Message | undefined,
): ComponentProps<typeof IFrame> => {
  let [searchParams] = useSearchParams();
  const ref = useRef<HTMLIFrameElement | null>(null);
  const config = getConfig('splat', page);
  const basePath = config.url;
  const match = useMatch(`/${config.path}/*`);
  const src = `${basePath}/${match?.params['*'] ?? ''}?${searchParams}`;
  const { staticSrc } = useSynchronizeUrl(ref, src, basePath);
  useIframeMessages(ref, 'Legacy Splat', config.path, localMessageHandler);
  return {
    ref,
    basePath: basePath,
    src: staticSrc,
    title: config.title,
  };
};

export { useSplatAdapter };
