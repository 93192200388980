import {
  createBrowserRouter,
  Navigate,
  Outlet,
  redirect,
  RouteObject,
} from 'react-router-dom';
import { Customers } from 'components/pages/Customers';
import { ErrorNotFound } from 'components/pages/ErrorNotFound';
import { Dashboard } from 'components/pages/Dashboard';
import { Profile } from 'components/pages/Profile';
import { Performance } from 'components/pages/Performance';
import { SideNavWithContent } from 'components/templates/SideNavWithContent';
import { Appointments } from 'components/pages/Appointments';
import { Support } from 'components/pages/Support';
import { AuthProvider } from 'components/organisms/Authentication';
import { Doors } from 'components/pages/Doors';
import { PageWrapper } from 'components/organisms/PageWrapper';
import { ChannelSelection } from 'components/pages/ChannelSelection';
import { ViewCustomer } from 'components/pages/ViewCustomer';
import { CreateCustomer } from 'components/pages/CreateCustomer';
import { Masquerade } from 'components/pages/Masquerade';
import { ScheduleAppointment } from 'components/pages/ScheduleAppointment';
import { NotificationsList } from 'components/pages/NotificationsList';
import { ChannelSelectionDirector } from 'components/organisms/ChannelSelectionDirector';
import { getNewPath } from 'adapters/common';
import { CustomerProfile } from 'components/pages/CustomerProfile';
import { Metrics } from 'components/pages/Metrics';

type Path =
  | '/'
  | '/appointments'
  | '/dashboard'
  | '/newLeads/channel-selection'
  | '/customers'
  | '/customers/create'
  | '/customers/customer-profile'
  | '/map'
  | '/metrics'
  | '/notifications'
  | '/performance'
  | '/masquerade'
  | '/profile'
  | '/support'
  | '*';

type DeepPath = Path | `${Path}/*`;

const Redirect = (props: { to: string }) => {
  const path = getNewPath(props.to);
  return <Navigate to={path} replace />;
};

const routes: Array<RouteObject & { path: DeepPath }> = [
  {
    path: '/',
    element: (
      <SideNavWithContent>
        <PageWrapper>
          <AuthProvider>
            <Outlet />
            <ChannelSelectionDirector />
          </AuthProvider>
        </PageWrapper>
      </SideNavWithContent>
    ),
    children: [
      {
        path: 'appointments/details/:id',
        loader: ({ params }) => {
          return redirect(`/customers/details/${params.id}`);
        },
      },
      {
        path: 'appointments/prospects/:id',
        loader: ({ params }) => {
          return redirect(`/customers/prospects/${params.id}`);
        },
      },
      {
        path: 'appointments',
        element: <Appointments />,
      },
      {
        path: 'customers/*',
        element: <Customers />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'map/*',
        element: <Doors />,
      },
      {
        path: 'performance',
        element: <Performance />,
      },
      {
        path: 'masquerade',
        element: <Masquerade />,
      },
      {
        path: 'metrics',
        element: <Metrics />,
      },
      {
        path: 'notifications',
        element: <NotificationsList />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'support',
        element: <Support />,
      },
      {
        path: 'customers/create',
        element: <CreateCustomer />,
      },
      {
        path: 'newLeads/channel-selection',
        element: <ChannelSelection />,
      },
      {
        path: 'customers/prospects/:id',
        element: <ViewCustomer />,
      },
      {
        path: 'customers/customer-profile/:id',
        element: <CustomerProfile />,
      },
      {
        path: '/schedule-appointment/:id',
        element: <ScheduleAppointment />,
      },
      {
        path: '/',
        element: <Redirect to="/dashboard" />,
      },
      {
        path: '*',
        element: <ErrorNotFound />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export { router };
export type { Path };
