import { ComponentProps, useRef } from 'react';
import { useIframeMessages } from './common';
import { getConfig } from './config';
import type { IFrame } from 'components/atoms/IFrame';

const useDashboardAdapter = (): ComponentProps<typeof IFrame> => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const config = getConfig('dashboard', 'dashboard');
  const src = `${config.url}`;
  useIframeMessages(ref, 'Dashboard', config.path);
  return {
    ref,
    basePath: src,
    src,
    title: config.title,
  };
};

export { useDashboardAdapter };
