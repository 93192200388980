import styled from '@emotion/styled/macro';
import { ReactComponent as Spinner } from '../../assets/images/spinner-white.svg';

type PropTypes = {
  className?: string;
  height?: number;
  width?: number;
};

const LoadingIndicator = ({
  className,
  height = 30,
  width = 30,
}: PropTypes) => {
  return (
    <SpinnerAnim
      className={className}
      height={`${height}px`}
      width={`${width}px`}
    />
  );
};

const SpinnerAnim = styled(Spinner)`
  @keyframes spin {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(-1turn);
    }
  }
  animation: spin;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export { LoadingIndicator };
